import React from "react"
import Header from '../components/header'
import { Container, Row, Col } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import { withPrefix } from "gatsby";

export default function Courses() {
  return (
    <>
    
    <Header activePage="Courses" />
    <Container className="fluid">
      <img width={"99%"} src={withPrefix("../header.png")} />
    <h1 style={{paddingTop: "15px"}}>Experience Data-enabled Design!</h1>
    <Row>
        <Col xs={8} style={{textAlign: "justify", paddingRight: "50px" }}>
          <h2>the course</h2>
          <p>In this course, we invite participants to actively engage in building creative data probes, analysing the data and using the insights from the data for design interventions in an iterative way. In the course we focus on combining qualitative and quantitative data and turning them into meaningful data narratives to inspire design. The course is a compressed version of an Industrial Design MSc level course, that we have been teaching for the last three years.</p>
          <p>In the course we will take a hands-on approach, where participants will be invited to collect and annotate their own data during the conference. We also encourage participants to bring their own data trackers and data tracking practices to the course, creativity is very much appreciated! Participants will receive materials prior to the conference to prepare for the course in more detail. The course will consist of 3 timeslots of 75 minutes each, spread throughout the conference.</p>
          {/* <p>We invite all students, researchers and practitioners from the CHI community to participate in our introductory Data-enabled Design course about a situated design method for intelligent ecosystems. Registrations are open! To sign up, look for <b>C05 Introduction to Data-Enabled Design</b> on the <a href="https://cvent.me/AgNgla" target="_blank">CHI registration page</a>.</p> */}
          <p>For more information, please <a rel="noopener noreferrer" href={withPrefix('./submission.pdf')} target="_blank">read our submission</a>!</p> 
          <p>You can also explore some of our students’ work under the Projects tab!</p>
          {/* <p>Looking forward to seeing you in New Orleans!</p> */}
          <p><a href="https://www.linkedin.com/in/peterlovei/">Peter Lovei</a>, <a href="https://reneenoortman.com/">Renee Noortman</a>, <a href="https://mathias-funk.com">Mathias Funk</a></p>
          <div style={{textAlign: "center"}}><img width={"80%"} src={withPrefix("../logos.png")} /> </div>
        </Col>
        <Col xs={4}>
          {/* <h2>Important dates</h2>
          <ul>
            <li><s>21 March: Early-bird registration</s></li>
            <li><s>22 March - 29 April: Regular registration</s></li>
            <li>30 April and later: Late registration</li>
            <li>30 April- 6 May: CHI 2022
              <ul>
                <li>4 May: Course Day</li>
              </ul>
            </li>
          </ul> */}

          {/* <h2>Planned sessions</h2>
          <p>The course will be divided into three 75 minutes sessions. The covered topics will be the following:</p>
          <h4>Session 1 - 09:00-10:15</h4>
          <ul>
            <li>09:00 - 09:15: Welcome and short round of introductions</li>
            <li>09:15 - 09:30: Introduction to Data-enabled Design method</li>
            <li>09:30 - 09:45: Co-responsibility case study</li>
            <li>09:45-10:15: Receive probes, brainstorm where to place them, check Data Foundry connection</li>
          </ul>
          <h4>Session 2 - 14:15-15:30</h4>
          <ul>
            <li>14:15 - 14:30: Asking questions, from data to insights</li>
            <li>14:30 - 15:00: Discussion about collected data, visualization</li>
            <li>15:00 - 15:30: Data-enabled Interviews with other teams</li>
          </ul>
          <h4>Session 3 - 16:15-17:30</h4>
          <ul>
            <li>16:15 - 16:30: Informed step</li>
            <li>16:30 - 17:00: What to design based on collected data?</li>
            <li>17:00 - 17:30: Pitches, recap, end of day</li>
          </ul> */}

        </Col>
      </Row>

      {/* <p>Our website is currently under construction. However we invite you to  <a rel="noopener noreferrer" href={withPrefix('./submission.pdf')} target="_blank">read our submission</a> that got this course accepted for the conference</p> */}
      







      
  

      


      {/* <Row>
        <Col>
          <h2>Background</h2>
          <p style={{textAlign: 'justify'}}>Although data plays an increasingly important role in the intelligent products, services and systems we design for, it is not yet so present in contemporary design processes. Established data design methods, as data-driven design (King.2017), emphasize the power of data to evaluate and validate design decisions. Data-enabled design (DED) (vanKollenburg:2019wn) provides a more designerly perspective, where data is used as creative material to inspire and inform new design interventions. DED is characterized as a quick and iterative approach that sets out to design, deploy and remotely adapt prototypes while they are situated in the daily life of people. Design researchers and practitioners use DED to acquire in-depth contextual insights of both quantitative and qualitative nature, and inform their subsequent, iterative design steps.<br/>
          Participants of this course will learn how to use data as creative material for design, both at conceptual and at hands-on prototyping level. We will show participants how to setup and execute contextual design explorations that are open and flexible so they allow for a variety of contexts, scenarios and situations. Participants of this course will collect contextual, behavioral and experiential data using both sensor data and qualitative data in the form of data-enabled interviews and self reports. During the course the instructors and participants will together analyse the collected data to discover rich and nuanced personal stories and design opportunities.<br/>This course not only serves as a platform to teach the DED methodology, but is also meant as a place where different designers working with data can connect with each other and share their experiences around designing with data. The assignments will be done in groups of three or four participants, to stimulate an exchange of knowledge, and to allow participants to familiarise themselves with methods that others are using to design with data.</p>
        </Col>
        <Col>
          <h2>Logistics</h2>
          <div>
            <p>
              <b>Duration of the course:</b> 3x 75 minutes
            </p>
            <p>
              <b>Planned days:</b> 
              <ul>
                <li>Monday - 2x 75 minutes</li>
                <li>Thursday - 1x 75 minutes</li>
              </ul>
            </p>
            
          </div>

          <h2>Learning outcomes</h2>
        </Col>
      </Row> */}

    </Container>
    </>
  )
}
